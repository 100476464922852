@import '@/styles/artifacts.scss';






































































































































































































































































.action-link {
  margin-left: 10px;
  margin-right: 10px;
}
