@import '@/styles/artifacts.scss';





















































































































































































































.consent-field {
  ::v-deep p {
    white-space: normal;
  }
}
