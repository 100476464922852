@import '@/styles/artifacts.scss';



































































































































































































































































































































































































































































































































































































































































.calculation-fields {
  margin: 0;
  .toggle-secondary-fields {
    display: flex;
    justify-content: flex-end;
  }
  .calculation-inputs {
    flex: 0 0 100%;
    margin: 0;
    ::v-deep .ui-input-field {
      label {
        color: red;
      }
    }
  }
  .object-selection {
    margin: 0;
  }
}
.btn-submit {
  cursor: pointer;
  &[disabled] {
    opacity: 0.6;
  }
}
p.loading {
  color: red;
}
