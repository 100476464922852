@import '@/styles/artifacts.scss';





























































.text-overflow {
  @include textOverflow();
}
.text-light {
  color: colorVodafone(grey);
}
